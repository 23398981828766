var commonjsGlobal = typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : {};

var e,goog=goog||{},h=commonjsGlobal;function l(a){return "string"==typeof a}function n(a,b){a=a.split(".");b=b||h;for(var c=0;c<a.length;c++)if(b=b[a[c]],null==b)return null;return b}function p(){}
function q(a){var b=typeof a;if("object"==b)if(a){if(a instanceof Array)return "array";if(a instanceof Object)return b;var c=Object.prototype.toString.call(a);if("[object Window]"==c)return "object";if("[object Array]"==c||"number"==typeof a.length&&"undefined"!=typeof a.splice&&"undefined"!=typeof a.propertyIsEnumerable&&!a.propertyIsEnumerable("splice"))return "array";if("[object Function]"==c||"undefined"!=typeof a.call&&"undefined"!=typeof a.propertyIsEnumerable&&!a.propertyIsEnumerable("call"))return "function"}else return "null";
else if("function"==b&&"undefined"==typeof a.call)return "object";return b}function r(a){return "array"==q(a)}function aa(a){var b=q(a);return "array"==b||"object"==b&&"number"==typeof a.length}function t(a){var b=typeof a;return "object"==b&&null!=a||"function"==b}var u="closure_uid_"+(1E9*Math.random()>>>0),ba=0;function ca(a,b,c){return a.call.apply(a.bind,arguments)}
function da(a,b,c){if(!a)throw Error();if(2<arguments.length){var d=Array.prototype.slice.call(arguments,2);return function(){var c=Array.prototype.slice.call(arguments);Array.prototype.unshift.apply(c,d);return a.apply(b,c)}}return function(){return a.apply(b,arguments)}}function v(a,b,c){Function.prototype.bind&&-1!=Function.prototype.bind.toString().indexOf("native code")?v=ca:v=da;return v.apply(null,arguments)}
function w(a,b){var c=Array.prototype.slice.call(arguments,1);return function(){var b=c.slice();b.push.apply(b,arguments);return a.apply(this,b)}}var x=Date.now||function(){return +new Date};function y(a,b){function c(){}c.prototype=b.prototype;a.S=b.prototype;a.prototype=new c;a.prototype.constructor=a;a.re=function(a,c,g){for(var d=Array(arguments.length-2),f=2;f<arguments.length;f++)d[f-2]=arguments[f];return b.prototype[c].apply(a,d)};}function z(){this.i=this.i;this.j=this.j;}var ea=0;z.prototype.i=!1;z.prototype.La=function(){if(!this.i&&(this.i=!0,this.G(),0!=ea)){var a=this[u]||(this[u]=++ba);}};z.prototype.G=function(){if(this.j)for(;this.j.length;)this.j.shift()();};var ha=Array.prototype.indexOf?function(a,b){return Array.prototype.indexOf.call(a,b,void 0)}:function(a,b){if(l(a))return l(b)&&1==b.length?a.indexOf(b,0):-1;for(var c=0;c<a.length;c++)if(c in a&&a[c]===b)return c;return -1},A=Array.prototype.forEach?function(a,b,c){Array.prototype.forEach.call(a,b,c);}:function(a,b,c){for(var d=a.length,f=l(a)?a.split(""):a,g=0;g<d;g++)g in f&&b.call(c,f[g],g,a);};
function ia(a){a:{var b=ja;for(var c=a.length,d=l(a)?a.split(""):a,f=0;f<c;f++)if(f in d&&b.call(void 0,d[f],f,a)){b=f;break a}b=-1;}return 0>b?null:l(a)?a.charAt(b):a[b]}function ka(a){return Array.prototype.concat.apply([],arguments)}function la(a){var b=a.length;if(0<b){for(var c=Array(b),d=0;d<b;d++)c[d]=a[d];return c}return []}function B(a){return /^[\s\xa0]*$/.test(a)}var ma=String.prototype.trim?function(a){return a.trim()}:function(a){return /^[\s\xa0]*([\s\S]*?)[\s\xa0]*$/.exec(a)[1]};function C(a,b){return -1!=a.indexOf(b)}function na(a,b){return a<b?-1:a>b?1:0}var D;a:{var oa=h.navigator;if(oa){var pa=oa.userAgent;if(pa){D=pa;break a}}D="";}function qa(a,b,c){for(var d in a)b.call(c,a[d],d,a);}function ra(a){var b={},c;for(c in a)b[c]=a[c];return b}var sa="constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf".split(" ");function ta(a,b){for(var c,d,f=1;f<arguments.length;f++){d=arguments[f];for(c in d)a[c]=d[c];for(var g=0;g<sa.length;g++)c=sa[g],Object.prototype.hasOwnProperty.call(d,c)&&(a[c]=d[c]);}}function ua(a){ua[" "](a);return a}ua[" "]=p;function va(a,b){var c=wa;return Object.prototype.hasOwnProperty.call(c,a)?c[a]:c[a]=b(a)}var xa=C(D,"Opera"),E=C(D,"Trident")||C(D,"MSIE"),ya=C(D,"Edge"),za=ya||E,Aa=C(D,"Gecko")&&!(C(D.toLowerCase(),"webkit")&&!C(D,"Edge"))&&!(C(D,"Trident")||C(D,"MSIE"))&&!C(D,"Edge"),Ba=C(D.toLowerCase(),"webkit")&&!C(D,"Edge");function Ca(){var a=h.document;return a?a.documentMode:void 0}var Da;
a:{var Ea="",Fa=function(){var a=D;if(Aa)return /rv:([^\);]+)(\)|;)/.exec(a);if(ya)return /Edge\/([\d\.]+)/.exec(a);if(E)return /\b(?:MSIE|rv)[: ]([^\);]+)(\)|;)/.exec(a);if(Ba)return /WebKit\/(\S+)/.exec(a);if(xa)return /(?:Version)[ \/]?(\S+)/.exec(a)}();Fa&&(Ea=Fa?Fa[1]:"");if(E){var Ga=Ca();if(null!=Ga&&Ga>parseFloat(Ea)){Da=String(Ga);break a}}Da=Ea;}var wa={};
function Ha(a){return va(a,function(){for(var b=0,c=ma(String(Da)).split("."),d=ma(String(a)).split("."),f=Math.max(c.length,d.length),g=0;0==b&&g<f;g++){var k=c[g]||"",m=d[g]||"";do{k=/(\d*)(\D*)(.*)/.exec(k)||["","","",""];m=/(\d*)(\D*)(.*)/.exec(m)||["","","",""];if(0==k[0].length&&0==m[0].length)break;b=na(0==k[1].length?0:parseInt(k[1],10),0==m[1].length?0:parseInt(m[1],10))||na(0==k[2].length,0==m[2].length)||na(k[2],m[2]);k=k[3];m=m[3];}while(0==b)}return 0<=b})}var Ia;var Ka=h.document;
Ia=Ka&&E?Ca()||("CSS1Compat"==Ka.compatMode?parseInt(Da,10):5):void 0;var La=!E||9<=Number(Ia),Ma=E&&!Ha("9"),Na=function(){if(!h.addEventListener||!Object.defineProperty)return !1;var a=!1,b=Object.defineProperty({},"passive",{get:function(){a=!0;}});try{h.addEventListener("test",p,b),h.removeEventListener("test",p,b);}catch(c){}return a}();function F(a,b){this.type=a;this.a=this.target=b;this.b=!1;this.hc=!0;}F.prototype.c=function(){this.hc=!1;};function G(a,b){F.call(this,a?a.type:"");this.relatedTarget=this.a=this.target=null;this.button=this.screenY=this.screenX=this.clientY=this.clientX=0;this.key="";this.metaKey=this.shiftKey=this.altKey=this.ctrlKey=!1;this.pointerId=0;this.pointerType="";this.f=null;a&&this.g(a,b);}y(G,F);var Oa={2:"touch",3:"pen",4:"mouse"};
G.prototype.g=function(a,b){var c=this.type=a.type,d=a.changedTouches&&a.changedTouches.length?a.changedTouches[0]:null;this.target=a.target||a.srcElement;this.a=b;if(b=a.relatedTarget){if(Aa){a:{try{ua(b.nodeName);var f=!0;break a}catch(g){}f=!1;}f||(b=null);}}else"mouseover"==c?b=a.fromElement:"mouseout"==c&&(b=a.toElement);this.relatedTarget=b;d?(this.clientX=void 0!==d.clientX?d.clientX:d.pageX,this.clientY=void 0!==d.clientY?d.clientY:d.pageY,this.screenX=d.screenX||0,this.screenY=d.screenY||0):
(this.clientX=void 0!==a.clientX?a.clientX:a.pageX,this.clientY=void 0!==a.clientY?a.clientY:a.pageY,this.screenX=a.screenX||0,this.screenY=a.screenY||0);this.button=a.button;this.key=a.key||"";this.ctrlKey=a.ctrlKey;this.altKey=a.altKey;this.shiftKey=a.shiftKey;this.metaKey=a.metaKey;this.pointerId=a.pointerId||0;this.pointerType=l(a.pointerType)?a.pointerType:Oa[a.pointerType]||"";this.f=a;a.defaultPrevented&&this.c();};
G.prototype.c=function(){G.S.c.call(this);var a=this.f;if(a.preventDefault)a.preventDefault();else if(a.returnValue=!1,Ma)try{if(a.ctrlKey||112<=a.keyCode&&123>=a.keyCode)a.keyCode=-1;}catch(b){}};var H="closure_listenable_"+(1E6*Math.random()|0),Pa=0;function Qa(a,b,c,d,f){this.listener=a;this.proxy=null;this.src=b;this.type=c;this.capture=!!d;this.ya=f;this.key=++Pa;this.ja=this.oa=!1;}Qa.prototype.a=function(){this.ja=!0;this.ya=this.src=this.proxy=this.listener=null;};function Ra(a){this.src=a;this.a={};this.b=0;}e=Ra.prototype;e.add=function(a,b,c,d,f){var g=a.toString();a=this.a[g];a||(a=this.a[g]=[],this.b++);var k=Sa(a,b,d,f);-1<k?(b=a[k],c||(b.oa=!1)):(b=new Qa(b,this.src,g,!!d,f),b.oa=c,a.push(b));return b};e.Sc=function(a,b,c,d){a=a.toString();if(a in this.a){var f=this.a[a];b=Sa(f,b,c,d);-1<b&&(f[b].a(),Array.prototype.splice.call(f,b,1),0==f.length&&(delete this.a[a],this.b--));}};
e.fc=function(a){var b=a.type;if(b in this.a){var c=this.a[b],d=ha(c,a),f;(f=0<=d)&&Array.prototype.splice.call(c,d,1);f&&(a.a(),0==this.a[b].length&&(delete this.a[b],this.b--));}};e.Tc=function(){var b;for(b in this.a){for(var c=this.a[b],d=0;d<c.length;d++)c[d].a();delete this.a[b];this.b--;}};e.Rc=function(a,b,c,d){a=this.a[a.toString()];var f=-1;a&&(f=Sa(a,b,c,d));return -1<f?a[f]:null};
function Sa(a,b,c,d){for(var f=0;f<a.length;++f){var g=a[f];if(!g.ja&&g.listener==b&&g.capture==!!c&&g.ya==d)return f}return -1}var Ta="closure_lm_"+(1E6*Math.random()|0),Ua={};function Wa(a,b,c,d,f){if(d&&d.once)return Xa(a,b,c,d,f);if(r(b)){for(var g=0;g<b.length;g++)Wa(a,b[g],c,d,f);return null}c=Ya(c);return a&&a[H]?a.Lb(b,c,t(d)?!!d.capture:!!d,f):Za(a,b,c,!1,d,f)}
function Za(a,b,c,d,f,g){if(!b)throw Error("Invalid event type");var k=t(f)?!!f.capture:!!f;if(k&&!La)return null;var m=$a(a);m||(a[Ta]=m=new Ra(a));c=m.add(b,c,d,k,g);if(c.proxy)return c;d=ab();c.proxy=d;d.src=a;d.listener=c;if(a.addEventListener)Na||(f=k),void 0===f&&(f=!1),a.addEventListener(b.toString(),d,f);else if(a.attachEvent)a.attachEvent(bb(b.toString()),d);else if(a.addListener&&a.removeListener)a.addListener(d);else throw Error("addEventListener and attachEvent are unavailable.");return c}function ab(){var a=cb,b=La?function(c){return a.call(b.src,b.listener,c)}:function(c){c=a.call(b.src,b.listener,c);if(!c)return c};return b}function Xa(a,b,c,d,f){if(r(b)){for(var g=0;g<b.length;g++)Xa(a,b[g],c,d,f);return null}c=Ya(c);return a&&a[H]?a.Mb(b,c,t(d)?!!d.capture:!!d,f):Za(a,b,c,!0,d,f)}function db(a,b,c,d,f){if(r(b))for(var g=0;g<b.length;g++)db(a,b[g],c,d,f);else d=t(d)?!!d.capture:!!d,c=Ya(c),a&&a[H]?a.Qc(b,c,d,f):a&&(a=$a(a))&&(b=a.Rc(b,c,d,f))&&eb(b);}
function eb(a){if("number"!=typeof a&&a&&!a.ja){var b=a.src;if(b&&b[H])b.nc(a);else{var c=a.type,d=a.proxy;b.removeEventListener?b.removeEventListener(c,d,a.capture):b.detachEvent?b.detachEvent(bb(c),d):b.addListener&&b.removeListener&&b.removeListener(d);(c=$a(b))?(c.fc(a),0==c.b&&(c.src=null,b[Ta]=null)):a.a();}}}function bb(a){return a in Ua?Ua[a]:Ua[a]="on"+a}function fb(a,b){var c=a.listener,d=a.ya||a.src;a.oa&&eb(a);return c.call(d,b)}
function cb(a,b){return a.ja?!0:La?fb(a,new G(b,this)):(b=new G(b||n("window.event"),this),fb(a,b))}function $a(a){a=a[Ta];return a instanceof Ra?a:null}var gb="__closure_events_fn_"+(1E9*Math.random()>>>0);function Ya(a){if("function"==q(a))return a;a[gb]||(a[gb]=function(b){return a.handleEvent(b)});return a[gb]}function I(){z.call(this);this.c=new Ra(this);this.K=this;this.F=null;}y(I,z);I.prototype[H]=!0;e=I.prototype;e.addEventListener=function(a,b,c,d){Wa(this,a,b,c,d);};e.removeEventListener=function(a,b,c,d){db(this,a,b,c,d);};
e.dispatchEvent=function(a){var b,c=this.F;if(c)for(b=[];c;c=c.F)b.push(c);c=this.K;var d=a.type||a;if(l(a))a=new F(a,c);else if(a instanceof F)a.target=a.target||c;else{var f=a;a=new F(d,c);ta(a,f);}f=!0;if(b)for(var g=b.length-1;!a.b&&0<=g;g--){var k=a.a=b[g];f=k.ua(d,!0,a)&&f;}a.b||(k=a.a=c,f=k.ua(d,!0,a)&&f,a.b||(f=k.ua(d,!1,a)&&f));if(b)for(g=0;!a.b&&g<b.length;g++)k=a.a=b[g],f=k.ua(d,!1,a)&&f;return f};e.G=function(){I.S.G.call(this);this.Id();this.F=null;};
e.Lb=function(a,b,c,d){return this.c.add(String(a),b,!1,c,d)};e.Mb=function(a,b,c,d){return this.c.add(String(a),b,!0,c,d)};e.Qc=function(a,b,c,d){this.c.Sc(String(a),b,c,d);};e.nc=function(a){this.c.fc(a);};e.Id=function(){this.c&&this.c.Tc();};e.ua=function(a,b,c){a=this.c.a[String(a)];if(!a)return !0;a=a.concat();for(var d=!0,f=0;f<a.length;++f){var g=a[f];if(g&&!g.ja&&g.capture==b){var k=g.listener,m=g.ya||g.src;g.oa&&this.nc(g);d=!1!==k.call(m,c)&&d;}}return d&&0!=c.hc};var hb=h.JSON.stringify;function ib(a,b){this.g=100;this.c=a;this.h=b;this.b=0;this.a=null;}ib.prototype.get=function(){if(0<this.b){this.b--;var a=this.a;this.a=a.next;a.next=null;}else a=this.c();return a};ib.prototype.f=function(a){this.h(a);this.b<this.g&&(this.b++,a.next=this.a,this.a=a);};function J(){this.b=this.a=null;}var kb=new ib(function(){return new jb},function(a){a.reset();});J.prototype.add=function(a,b){var c=this.c();c.set(a,b);this.b?this.b.next=c:this.a=c;this.b=c;};J.prototype.f=function(){var a=null;this.a&&(a=this.a,this.a=this.a.next,this.a||(this.b=null),a.next=null);return a};J.prototype.g=function(a){kb.f(a);};J.prototype.c=function(){return kb.get()};function jb(){this.next=this.b=this.a=null;}jb.prototype.set=function(a,b){this.a=a;this.b=b;this.next=null;};
jb.prototype.reset=function(){this.next=this.b=this.a=null;};function lb(a){h.setTimeout(function(){throw a;},0);}var mb;function nb(){var a=h.Promise.resolve(void 0);mb=function(){a.then(ob);};}var pb=!1,qb=new J;function ob(){for(var a;a=qb.f();){try{a.a.call(a.b);}catch(b){lb(b);}qb.g(a);}pb=!1;}function rb(a,b){I.call(this);this.b=a||1;this.a=b||h;this.f=v(this.$d,this);this.g=x();}y(rb,I);e=rb.prototype;e.wa=!1;e.P=null;e.$d=function(){if(this.wa){var a=x()-this.g;0<a&&a<.8*this.b?this.P=this.a.setTimeout(this.f,this.b-a):(this.P&&(this.a.clearTimeout(this.P),this.P=null),this.Gc(),this.wa&&(this.Na(),this.start()));}};e.Gc=function(){this.dispatchEvent("tick");};e.start=function(){this.wa=!0;this.P||(this.P=this.a.setTimeout(this.f,this.b),this.g=x());};
e.Na=function(){this.wa=!1;this.P&&(this.a.clearTimeout(this.P),this.P=null);};e.G=function(){rb.S.G.call(this);this.Na();delete this.a;};function sb(a,b,c){if("function"==q(a))c&&(a=v(a,c));else if(a&&"function"==typeof a.handleEvent)a=v(a.handleEvent,a);else throw Error("Invalid listener argument");return 2147483647<Number(b)?-1:h.setTimeout(a,b||0)}function tb(a,b,c){z.call(this);this.f=null!=c?v(a,c):a;this.c=b;this.b=v(this.Cd,this);this.a=[];}y(tb,z);e=tb.prototype;e.Ca=!1;e.ec=0;e.ba=null;e.Hc=function(a){this.a=arguments;this.ba||this.ec?this.Ca=!0:this.yb();};e.Pc=function(){this.ba&&(h.clearTimeout(this.ba),this.ba=null,this.Ca=!1,this.a=[]);};e.G=function(){tb.S.G.call(this);this.Pc();};e.Cd=function(){this.ba=null;this.Ca&&!this.ec&&(this.Ca=!1,this.yb());};e.yb=function(){this.ba=sb(this.b,this.c);this.f.apply(null,this.a);};function ub(a){z.call(this);this.b=a;this.a={};}y(ub,z);var vb=[];e=ub.prototype;e.Jb=function(a,b,c){this.wd(a,b,c);};e.wd=function(a,b,c){r(b)||(b&&(vb[0]=b.toString()),b=vb);for(var d=0;d<b.length;d++){var f=Wa(a,b[d],c||this.handleEvent,!1,this.b||this);if(!f)break;this.a[f.key]=f;}};e.Kb=function(){qa(this.a,function(a,b){this.a.hasOwnProperty(b)&&eb(a);},this);this.a={};};e.G=function(){ub.S.G.call(this);this.Kb();};
e.handleEvent=function(){throw Error("EventHandler.handleEvent not implemented");};function wb(){}var K=new I;function xb(a){F.call(this,"serverreachability",a);}y(xb,F);function L(a){K.dispatchEvent(new xb(K,a));}function yb(a){F.call(this,"statevent",a);}y(yb,F);function M(a){K.dispatchEvent(new yb(K,a));}function zb(a){F.call(this,"timingevent",a);}y(zb,F);function N(a,b){if("function"!=q(a))throw Error("Fn must not be null and must be a function");return h.setTimeout(function(){a();},b)}var Ab={NO_ERROR:0,ae:1,he:2,ge:3,de:4,fe:5,ie:6,qc:7,TIMEOUT:8,le:9};var Bb={ce:"complete",pe:"success",rc:"error",qc:"abort",ne:"ready",oe:"readystatechange",TIMEOUT:"timeout",je:"incrementaldata",me:"progress",ee:"downloadprogress",qe:"uploadprogress"};function Cb(){}Cb.prototype.a=null;Cb.prototype.c=function(){return this.a||(this.a={})};function Db(){}var O={OPEN:"a",be:"b",rc:"c",ke:"d"};function Eb(){F.call(this,"d");}y(Eb,F);function Fb(){F.call(this,"c");}y(Fb,F);var Hb;function Ib(){}y(Ib,Cb);Ib.prototype.b=function(){return new XMLHttpRequest};Hb=new Ib;function P(a,b,c){this.g=a;this.da=b;this.ca=c||1;this.I=new ub(this);this.L=Jb;a=za?125:void 0;this.T=new rb(a);this.J=null;this.b=!1;this.i=this.C=this.f=this.F=this.u=this.U=this.h=null;this.j=[];this.a=null;this.A=0;this.c=this.v=null;this.o=-1;this.l=!1;this.K=0;this.B=null;this.s=this.Y=this.H=!1;}var Jb=45E3,Kb={},Lb={};e=P.prototype;e.ha=function(a){this.J=a;};e.setTimeout=function(a){this.L=a;};e.Xc=function(a){this.K=a;};e.Qd=function(a){this.j=a;};
e.cb=function(a,b){this.F=1;this.f=a.N().za();this.i=b;this.H=!0;this.ic(null);};e.bb=function(a,b,c){this.F=1;this.f=a.N().za();this.i=null;this.H=b;this.ic(c);};
e.ic=function(a){this.u=x();this.fa();this.C=this.f.N();this.C.Aa("t",this.ca);this.A=0;this.a=this.g.sa(this.g.Da()?a:null);0<this.K&&(this.B=new tb(v(this.oc,this,this.a),this.K));this.I.Jb(this.a,"readystatechange",this.Hd);a=this.J?ra(this.J):{};this.i?(this.v||(this.v="POST"),a["Content-Type"]="application/x-www-form-urlencoded",this.a.xa(this.C,this.v,this.i,a)):(this.v="GET",this.a.xa(this.C,this.v,null,a));L(1);};e.Hd=function(a){a=a.target;var b=this.B;b&&3==a.W()?b.Hc():this.oc(a);};
e.oc=function(a){try{a==this.a&&this.Ed();}catch(b){}finally{}};
e.Ed=function(){var a=this.a.W(),b=this.a.Db(),c=this.a.aa();if(!(3>a||3==a&&!za&&!this.a.va()))if(this.l||4!=a||7==b||(8==b||0>=c?L(3):L(2)),this.pa(),this.o=c=this.a.aa(),b=this.a.va(),this.b=200==c){if(this.Td())if(c=this.Kc())this.s=!0,this.Wa(c);else{this.b=!1;this.c=3;M(12);this.Z();this.ta();return}this.H?(this.vb(a,b),za&&this.b&&3==a&&this.Yd()):this.Wa(b);4==a&&this.Z();this.b&&!this.l&&(4==a?this.g.Va(this):(this.b=!1,this.fa()));}else 400==c&&0<b.indexOf("Unknown SID")?(this.c=3,M(12)):
(this.c=0,M(13)),this.Z(),this.ta();};e.Td=function(){return this.Y&&!this.s};e.Kc=function(){if(this.a){var a=this.a.ga("X-HTTP-Initial-Response");if(a&&!B(a))return a}return null};e.Md=function(){this.Y=!0;};e.vb=function(a,b){for(var c=!0;!this.l&&this.A<b.length;){var d=this.Lc(b);if(d==Lb){4==a&&(this.c=4,M(14),c=!1);break}else if(d==Kb){this.c=4;M(15);c=!1;break}else this.Wa(d);}4==a&&0==b.length&&(this.c=1,M(16),c=!1);this.b=this.b&&c;c||(this.Z(),this.ta());};
e.Gd=function(){if(this.a){var a=this.a.W(),b=this.a.va();this.A<b.length&&(this.pa(),this.vb(a,b),this.b&&4!=a&&this.fa());}};e.Yd=function(){this.I.Jb(this.T,"tick",this.Gd);this.T.start();};e.Lc=function(a){var b=this.A,c=a.indexOf("\n",b);if(-1==c)return Lb;b=Number(a.substring(b,c));if(isNaN(b))return Kb;c+=1;if(c+b>a.length)return Lb;a=a.substr(c,b);this.A=c+b;return a};
e.Ld=function(a){this.F=2;this.f=a.N().za();a=!1;h.navigator&&h.navigator.sendBeacon&&(a=h.navigator.sendBeacon(this.f.toString(),""));!a&&h.Image&&((new Image).src=this.f,a=!0);a||(this.a=this.g.sa(null),this.a.xa(this.f));this.u=x();this.fa();};e.cancel=function(){this.l=!0;this.Z();};e.Kd=function(a){a&&this.setTimeout(a);this.h&&(this.pa(),this.fa());};e.fa=function(){this.U=x()+this.L;this.mc(this.L);};
e.mc=function(a){if(null!=this.h)throw Error("WatchDog timer not null");this.h=N(v(this.Dd,this),a);};e.pa=function(){this.h&&(h.clearTimeout(this.h),this.h=null);};e.Dd=function(){this.h=null;var a=x();0<=a-this.U?this.md():this.mc(this.U-a);};e.md=function(){2!=this.F&&(L(3),M(17));this.Z();this.c=2;this.ta();};e.ta=function(){this.g.Tb()||this.l||this.g.Va(this);};
e.Z=function(){this.pa();var a=this.B;a&&"function"==typeof a.La&&a.La();this.B=null;this.T.Na();this.I.Kb();this.a&&(a=this.a,this.a=null,a.abort(),a.La());};e.Wa=function(a){try{this.g.bc(this,a),L(4);}catch(b){}};function Mb(a){if(a.D&&"function"==typeof a.D)return a.D();if(l(a))return a.split("");if(aa(a)){for(var b=[],c=a.length,d=0;d<c;d++)b.push(a[d]);return b}b=[];c=0;for(d in a)b[c++]=a[d];return b}
function Nb(a,b){if(a.forEach&&"function"==typeof a.forEach)a.forEach(b,void 0);else if(aa(a)||l(a))A(a,b,void 0);else{if(a.O&&"function"==typeof a.O)var c=a.O();else if(a.D&&"function"==typeof a.D)c=void 0;else if(aa(a)||l(a)){c=[];for(var d=a.length,f=0;f<d;f++)c.push(f);}else for(f in c=[],d=0,a)c[d++]=f;d=Mb(a);f=d.length;for(var g=0;g<f;g++)b.call(void 0,d[g],c&&c[g],a);}}function Q(a,b){this.b={};this.a=[];this.c=0;var c=arguments.length;if(1<c){if(c%2)throw Error("Uneven number of arguments");for(var d=0;d<c;d+=2)this.set(arguments[d],arguments[d+1]);}else a&&this.fd(a);}e=Q.prototype;e.D=function(){this.Ka();for(var a=[],b=0;b<this.a.length;b++)a.push(this.b[this.a[b]]);return a};e.O=function(){this.Ka();return this.a.concat()};e.Ra=function(a){return R(this.b,a)};e.hd=function(){return 0==this.c};e.gd=function(){this.b={};this.c=this.a.length=0;};
e.Qb=function(a){R(this.b,a)&&(delete this.b[a],this.c--,this.a.length>2*this.c&&this.Ka());};e.Ka=function(){if(this.c!=this.a.length){for(var a=0,b=0;a<this.a.length;){var c=this.a[a];R(this.b,c)&&(this.a[b++]=c);a++;}this.a.length=b;}if(this.c!=this.a.length){var d={};for(b=a=0;a<this.a.length;)c=this.a[a],R(d,c)||(this.a[b++]=c,d[c]=1),a++;this.a.length=b;}};e.get=function(a,b){return R(this.b,a)?this.b[a]:b};e.set=function(a,b){R(this.b,a)||(this.c++,this.a.push(a));this.b[a]=b;};
e.fd=function(a){if(a instanceof Q)for(var b=a.O(),c=0;c<b.length;c++)this.set(b[c],a.get(b[c]));else for(b in a)this.set(b,a[b]);};e.forEach=function(a,b){for(var c=this.O(),d=0;d<c.length;d++){var f=c[d],g=this.get(f);a.call(b,g,f,this);}};e.Pb=function(){return new Q(this)};function R(a,b){return Object.prototype.hasOwnProperty.call(a,b)}var Ob=/^(?:([^:/?#.]+):)?(?:\/\/(?:([^/?#]*)@)?([^/#?]*?)(?::([0-9]+))?(?=[/#?]|$))?([^?#]+)?(?:\?([^#]*))?(?:#([\s\S]*))?$/;function Pb(a,b){if(a){a=a.split("&");for(var c=0;c<a.length;c++){var d=a[c].indexOf("="),f=null;if(0<=d){var g=a[c].substring(0,d);f=a[c].substring(d+1);}else g=a[c];b(g,f?decodeURIComponent(f.replace(/\+/g," ")):"");}}}function S(a,b){this.c=this.i=this.b="";this.h=null;this.j=this.g="";this.f=this.l=!1;var c;a instanceof S?(this.f=void 0!==b?b:a.f,this.ma(a.b),this.$a(a.i),this.ka(a.c),this.la(a.h),this.Ba(a.g),this.Za(a.a.Gb()),this.Ya(a.j)):a&&(c=String(a).match(Ob))?(this.f=!!b,this.ma(c[1]||"",!0),this.$a(c[2]||"",!0),this.ka(c[3]||"",!0),this.la(c[4]),this.Ba(c[5]||"",!0),this.Za(c[6]||"",!0),this.Ya(c[7]||"",!0)):(this.f=!!b,this.a=new T(null,this.f));}e=S.prototype;
e.toString=function(){var a=[],b=this.b;b&&a.push(U(b,Qb,!0),":");var c=this.c;if(c||"file"==b)a.push("//"),(b=this.i)&&a.push(U(b,Qb,!0),"@"),a.push(encodeURIComponent(String(c)).replace(/%25([0-9a-fA-F]{2})/g,"%$1")),c=this.h,null!=c&&a.push(":",String(c));if(c=this.g)this.Sa()&&"/"!=c.charAt(0)&&a.push("/"),a.push(U(c,"/"==c.charAt(0)?Rb:Sb,!0));(c=this.Ic())&&a.push("?",c);(c=this.j)&&a.push("#",U(c,Tb));return a.join("")};
e.resolve=function(a){var b=this.N(),c=a.qd();c?b.ma(a.b):c=a.rd();c?b.$a(a.i):c=a.Sa();c?b.ka(a.c):c=a.od();var d=a.g;if(c)b.la(a.h);else if(c=a.Sb()){if("/"!=d.charAt(0))if(this.Sa()&&!this.Sb())d="/"+d;else{var f=b.g.lastIndexOf("/");-1!=f&&(d=b.g.substr(0,f+1)+d);}f=d;if(".."==f||"."==f)d="";else if(C(f,"./")||C(f,"/.")){d=0==f.lastIndexOf("/",0);f=f.split("/");for(var g=[],k=0;k<f.length;){var m=f[k++];"."==m?d&&k==f.length&&g.push(""):".."==m?((1<g.length||1==g.length&&""!=g[0])&&g.pop(),d&&
k==f.length&&g.push("")):(g.push(m),d=!0);}d=g.join("/");}else d=f;}c?b.Ba(d):c=a.pd();c?b.Za(a.a.Gb()):c=a.nd();c&&b.Ya(a.j);return b};e.N=function(){return new S(this)};e.ma=function(a,b){this.M();if(this.b=b?V(a,!0):a)this.b=this.b.replace(/:$/,"");};e.qd=function(){return !!this.b};e.$a=function(a,b){this.M();this.i=b?V(a):a;};e.rd=function(){return !!this.i};e.ka=function(a,b){this.M();this.c=b?V(a,!0):a;};e.Sa=function(){return !!this.c};
e.la=function(a){this.M();if(a){a=Number(a);if(isNaN(a)||0>a)throw Error("Bad port number "+a);this.h=a;}else this.h=null;};e.od=function(){return null!=this.h};e.Ba=function(a,b){this.M();this.g=b?V(a,!0):a;};e.Sb=function(){return !!this.g};e.pd=function(){return ""!==this.a.toString()};e.Za=function(a,b){this.M();a instanceof T?(this.a=a,this.a.Oc(this.f)):(b||(a=U(a,Ub)),this.a=new T(a,this.f));};e.Ic=function(){return this.a.toString()};e.m=function(a,b){this.M();this.a.set(a,b);};
e.Aa=function(a,b){this.M();r(b)||(b=[String(b)]);this.a.lc(a,b);};e.Ya=function(a,b){this.M();this.j=b?V(a):a;};e.nd=function(){return !!this.j};e.za=function(){this.M();this.m("zx",Math.floor(2147483648*Math.random()).toString(36)+Math.abs(Math.floor(2147483648*Math.random())^x()).toString(36));return this};e.M=function(){if(this.l)throw Error("Tried to modify a read-only Uri");};function Vb(a){return a instanceof S?a.N():new S(a,void 0)}
function Wb(a,b,c,d){var f=new S(null,void 0);a&&f.ma(a);b&&f.ka(b);c&&f.la(c);d&&f.Ba(d);return f}function V(a,b){return a?b?decodeURI(a.replace(/%25/g,"%2525")):decodeURIComponent(a):""}function U(a,b,c){return l(a)?(a=encodeURI(a).replace(b,Xb),c&&(a=a.replace(/%25([0-9a-fA-F]{2})/g,"%$1")),a):null}function Xb(a){a=a.charCodeAt(0);return "%"+(a>>4&15).toString(16)+(a&15).toString(16)}var Qb=/[#\/\?@]/g,Sb=/[#\?:]/g,Rb=/[#\?]/g,Ub=/[#\?@]/g,Tb=/#/g;
function T(a,b){this.b=this.a=null;this.c=a||null;this.f=!!b;}e=T.prototype;e.V=function(){if(!this.a&&(this.a=new Q,this.b=0,this.c)){var a=this;Pb(this.c,function(b,c){a.add(decodeURIComponent(b.replace(/\+/g," ")),c);});}};e.add=function(a,b){this.V();this.ia();a=this.$(a);var c=this.a.get(a);c||this.a.set(a,c=[]);c.push(b);this.b+=1;return this};e.Ib=function(a){this.V();a=this.$(a);this.a.Ra(a)&&(this.ia(),this.b-=this.a.get(a).length,this.a.Qb(a));};e.Hb=function(a){this.V();a=this.$(a);return this.a.Ra(a)};
e.forEach=function(a,b){this.V();this.a.forEach(function(c,d){A(c,function(c){a.call(b,c,d,this);},this);},this);};e.O=function(){this.V();for(var a=this.a.D(),b=this.a.O(),c=[],d=0;d<b.length;d++)for(var f=a[d],g=0;g<f.length;g++)c.push(b[d]);return c};e.D=function(a){this.V();var b=[];if(l(a))this.Hb(a)&&(b=ka(b,this.a.get(this.$(a))));else{a=this.a.D();for(var c=0;c<a.length;c++)b=ka(b,a[c]);}return b};
e.set=function(a,b){this.V();this.ia();a=this.$(a);this.Hb(a)&&(this.b-=this.a.get(a).length);this.a.set(a,[b]);this.b+=1;return this};e.get=function(a,b){if(!a)return b;a=this.D(a);return 0<a.length?String(a[0]):b};e.lc=function(a,b){this.Ib(a);0<b.length&&(this.ia(),this.a.set(this.$(a),la(b)),this.b+=b.length);};
e.toString=function(){if(this.c)return this.c;if(!this.a)return "";for(var a=[],b=this.a.O(),c=0;c<b.length;c++){var d=b[c],f=encodeURIComponent(String(d));d=this.D(d);for(var g=0;g<d.length;g++){var k=f;""!==d[g]&&(k+="="+encodeURIComponent(String(d[g])));a.push(k);}}return this.c=a.join("&")};e.ia=function(){this.c=null;};e.Gb=function(){var a=new T;a.c=this.c;this.a&&(a.a=this.a.Pb(),a.b=this.b);return a};e.$=function(a){a=String(a);this.f&&(a=a.toLowerCase());return a};
e.Oc=function(a){a&&!this.f&&(this.V(),this.ia(),this.a.forEach(function(a,c){var b=c.toLowerCase();c!=b&&(this.Ib(c),this.lc(b,a));},this));this.f=a;};function Zb(){}function $b(){}y($b,Zb);function ac(a){this.a=a;this.b=this.h=null;this.g=!1;this.i=null;this.c=-1;this.l=this.f=null;}e=ac.prototype;e.R=null;e.Wc=function(a){this.h=a;};e.Vc=function(a){this.i=a;a=this.a.Cb(this.i);M(3);var b=this.a.H.b;null!=b?(this.f=this.a.Oa(b[0]),this.R=1,this.rb()):(a.Aa("MODE","init"),!this.a.o&&this.a.j&&a.Aa("X-HTTP-Session-Id",this.a.j),this.b=new P(this,void 0,void 0),this.b.ha(this.h),this.b.bb(a,!1,null),this.R=0);};
e.rb=function(){var a=this.a.H.a;if(null!=a)M(4),a?(M(10),this.a.na(this,!1)):(M(11),this.a.na(this,!0));else{this.b=new P(this,void 0,void 0);this.b.ha(this.h);a=this.a.Bb(this.f,this.i);M(4);a.Aa("TYPE","xmlhttp");var b=this.a.j,c=this.a.Fa;b&&c&&a.m(b,c);this.b.bb(a,!1,this.f);}};e.sa=function(a){return this.a.sa(a)};e.abort=function(){this.b&&(this.b.cancel(),this.b=null);this.c=-1;};e.Tb=function(){return !1};
e.bc=function(a,b){this.c=a.o;if(0==this.R)if(this.Uc(a),b){try{var c=this.a.U.a(b);}catch(d){this.a.ab(this);return}this.f=this.a.Oa(c[0]);}else this.a.ab(this);else 1==this.R&&(this.g?M(6):"11111"==b?(M(5),this.g=!0,this.Ac()&&(this.c=200,this.b.cancel(),M(11),this.a.na(this,!0))):(M(7),this.g=!1));};e.Va=function(){this.c=this.b.o;this.b.b?0==this.R?(this.R=1,this.rb()):1==this.R&&(this.g?(M(11),this.a.na(this,!0)):(M(10),this.a.na(this,!1))):(0==this.R?M(8):1==this.R&&M(9),this.a.ab(this));};
e.Uc=function(a){if(!this.a.o&&(a=a.a)){var b=a.ga("X-Client-Wire-Protocol");this.l=b?b:null;this.a.j&&(a=a.ga("X-HTTP-Session-Id"))&&this.a.kc(a);}};e.Da=function(){return this.a.Da()};e.Pa=function(){return this.a.Pa()};e.Ac=function(){return !E||10<=Number(Ia)};function bc(){this.a=this.b=null;}function cc(){this.a=new Q;}function dc(a){var b=typeof a;return "object"==b&&a||"function"==b?"o"+(a[u]||(a[u]=++ba)):b.charAt(0)+a}e=cc.prototype;e.add=function(a){this.a.set(dc(a),a);};e.ed=function(a){this.a.Qb(dc(a));};e.jd=function(){this.a.gd();};e.Rb=function(){return this.a.hd()};e.Ob=function(a){return this.a.Ra(dc(a))};e.D=function(){return this.a.D()};function ec(a,b){this.a=a;this.b=b;}function fc(a){this.g=a||gc;h.PerformanceNavigationTiming?(a=h.performance.getEntriesByType("navigation"),a=0<a.length&&("hq"==a[0].nextHopProtocol||"h2"==a[0].nextHopProtocol)):a=!!(h.Ja&&h.Ja.Vb&&h.Ja.Vb()&&h.Ja.Vb().te);this.f=a?this.g:1;this.a=null;1<this.f&&(this.a=new cc);this.b=null;this.c=[];}var gc=10;e=fc.prototype;e.kb=function(a){!this.a&&(C(a,"spdy")||C(a,"quic")||C(a,"h2"))&&(this.f=this.g,this.a=new cc,this.b&&(this.Ha(this.b),this.b=null));};
e.Ub=function(){return this.b?!0:this.a?this.a.a.c>=this.f:!1};e.Mc=function(){return this.b?1:this.a?this.a.a.c:0};e.Ta=function(a){return this.b?this.b==a:this.a?this.a.Ob(a):!1};e.Ha=function(a){this.a?this.a.add(a):this.b=a;};e.gc=function(a){this.b&&this.b==a?this.b=null:this.a&&this.a.Ob(a)&&this.a.ed(a);};e.cancel=function(){this.c=this.Xb();this.b?(this.b.cancel(),this.b=null):this.a&&!this.a.Rb()&&(A(this.a.D(),function(a){a.cancel();}),this.a.jd());};
e.Xb=function(){if(null!=this.b)return this.c.concat(this.b.j);if(null!=this.a&&!this.a.Rb()){var a=this.c;A(this.a.D(),function(b){a=a.concat(b.j);});return a}return la(this.c)};e.wc=function(a){this.c=this.c.concat(a);};e.Bc=function(){this.c.length=0;};function hc(){this.b=this.a=void 0;}hc.prototype.stringify=function(a){return h.JSON.stringify(a,this.a)};hc.prototype.parse=function(a){return h.JSON.parse(a,this.b)};function ic(){this.f=new hc;}ic.prototype.b=function(a,b,c){var d=c||"";try{Nb(a,function(a,c){var f=a;t(a)&&(f=hb(a));b.push(d+c+"="+encodeURIComponent(f));});}catch(f){throw b.push(d+"type="+encodeURIComponent("_badmap")),f;}};ic.prototype.c=function(a,b,c){for(var d=-1;;){var f=["count="+b];-1==d?0<b?(d=a[0].a,f.push("ofs="+d)):d=0:f.push("ofs="+d);for(var g=!0,k=0;k<b;k++){var m=a[k].a,Gb=a[k].b;m-=d;if(0>m)d=Math.max(0,a[k].a-100),g=!1;else try{this.b(Gb,f,"req"+m+"_");}catch(Ac){c&&c(Gb);}}if(g)return f.join("&")}};
ic.prototype.a=function(a){return this.f.parse(a)};function jc(a,b){var c=new wb,d=new Image;d.onload=w(kc,c,d,"TestLoadImage: loaded",!0,b);d.onerror=w(kc,c,d,"TestLoadImage: error",!1,b);d.onabort=w(kc,c,d,"TestLoadImage: abort",!1,b);d.ontimeout=w(kc,c,d,"TestLoadImage: timeout",!1,b);h.setTimeout(function(){if(d.ontimeout)d.ontimeout();},1E4);d.src=a;}function kc(a,b,c,d,f){try{b.onload=null,b.onerror=null,b.onabort=null,b.ontimeout=null,f(d);}catch(g){}}var lc=h.JSON.parse;function X(a){I.call(this);this.headers=new Q;this.l=a||null;this.b=!1;this.v=this.a=null;this.C="";this.h=0;this.f="";this.g=this.B=this.o=this.A=!1;this.u=0;this.s=null;this.J=mc;this.H=this.L=this.I=!1;}y(X,I);var mc="",nc=/^https?$/i,oc=["POST","PUT"];e=X.prototype;e.Sd=function(a){this.I=a;};
e.xa=function(a,b,c,d){if(this.a)throw Error("[goog.net.XhrIo] Object is active with another request="+this.C+"; newUri="+a);b=b?b.toUpperCase():"GET";this.C=a;this.f="";this.h=0;this.A=!1;this.b=!0;this.a=this.Ec();this.v=this.l?this.l.c():Hb.c();this.a.onreadystatechange=v(this.ac,this);this.L&&"onprogress"in this.a&&(this.a.onprogress=v(function(a){this.Zb(a,!0);},this),this.a.upload&&(this.a.upload.onprogress=v(this.Zb,this)));try{this.B=!0,this.a.open(b,String(a),!0),this.B=!1;}catch(g){this.Ab(g);
return}a=c||"";var f=this.headers.Pb();d&&Nb(d,function(a,b){f.set(b,a);});d=ia(f.O());c=h.FormData&&a instanceof h.FormData;!(0<=ha(oc,b))||d||c||f.set("Content-Type","application/x-www-form-urlencoded;charset=utf-8");f.forEach(function(a,b){this.a.setRequestHeader(b,a);},this);this.J&&(this.a.responseType=this.J);"withCredentials"in this.a&&this.a.withCredentials!==this.I&&(this.a.withCredentials=this.I);try{this.sb(),0<this.u&&((this.H=pc(this.a))?(this.a.timeout=this.u,this.a.ontimeout=v(this.Nb,
this)):this.s=sb(this.Nb,this.u,this)),this.o=!0,this.a.send(a),this.o=!1;}catch(g){this.Ab(g);}};function pc(a){return E&&Ha(9)&&"number"==typeof a.timeout&&void 0!==a.ontimeout}function ja(a){return "content-type"==a.toLowerCase()}e.Ec=function(){return this.l?this.l.b():Hb.b()};e.Nb=function(){"undefined"!=typeof goog&&this.a&&(this.f="Timed out after "+this.u+"ms, aborting",this.h=8,this.dispatchEvent("timeout"),this.abort(8));};
e.Ab=function(a){this.b=!1;this.a&&(this.g=!0,this.a.abort(),this.g=!1);this.f=a;this.h=5;this.xb();this.qa();};e.xb=function(){this.A||(this.A=!0,this.dispatchEvent("complete"),this.dispatchEvent("error"));};e.abort=function(a){this.a&&this.b&&(this.b=!1,this.g=!0,this.a.abort(),this.g=!1,this.h=a||7,this.dispatchEvent("complete"),this.dispatchEvent("abort"),this.qa());};e.G=function(){this.a&&(this.b&&(this.b=!1,this.g=!0,this.a.abort(),this.g=!1),this.qa(!0));X.S.G.call(this);};
e.ac=function(){this.i||(this.B||this.o||this.g?this.$b():this.Bd());};e.Bd=function(){this.$b();};e.$b=function(){if(this.b&&"undefined"!=typeof goog&&(!this.v[1]||4!=this.W()||2!=this.aa()))if(this.o&&4==this.W())sb(this.ac,0,this);else if(this.dispatchEvent("readystatechange"),this.td()){this.b=!1;try{this.vd()?(this.dispatchEvent("complete"),this.dispatchEvent("success")):(this.h=6,this.f=this.Fb()+" ["+this.aa()+"]",this.xb());}finally{this.qa();}}};
e.Zb=function(a,b){this.dispatchEvent(qc(a,"progress"));this.dispatchEvent(qc(a,b?"downloadprogress":"uploadprogress"));};function qc(a,b){return {type:b,lengthComputable:a.lengthComputable,loaded:a.loaded,total:a.total}}e.qa=function(a){if(this.a){this.sb();var b=this.a,c=this.v[0]?p:null;this.v=this.a=null;a||this.dispatchEvent("ready");try{b.onreadystatechange=c;}catch(d){}}};e.sb=function(){this.a&&this.H&&(this.a.ontimeout=null);this.s&&(h.clearTimeout(this.s),this.s=null);};
e.td=function(){return 4==this.W()};e.vd=function(){var a=this.aa();a:switch(a){case 200:case 201:case 202:case 204:case 206:case 304:case 1223:var b=!0;break a;default:b=!1;}return b||0===a&&!this.ud()};e.ud=function(){var a=String(this.C).match(Ob)[1]||null;!a&&h.self&&h.self.location&&(a=h.self.location.protocol,a=a.substr(0,a.length-1));return nc.test(a?a.toLowerCase():"")};e.W=function(){return this.a?this.a.readyState:0};e.aa=function(){try{return 2<this.W()?this.a.status:-1}catch(a){return -1}};
e.Fb=function(){try{return 2<this.W()?this.a.statusText:""}catch(a){return ""}};e.va=function(){try{return this.a?this.a.responseText:""}catch(a){return ""}};e.Nc=function(a){if(this.a){var b=this.a.responseText;a&&0==b.indexOf(a)&&(b=b.substring(a.length));return lc(b)}};e.ga=function(a){return this.a?this.a.getResponseHeader(a):null};e.Db=function(){return this.h};e.dd=function(){return l(this.f)?this.f:String(this.f)};function rc(a){var b="";qa(a,function(a,d){b+=d;b+=":";b+=a;b+="\r\n";});return b}function sc(a,b,c){a:{for(d in c){var d=!1;break a}d=!0;}if(d)return a;c=rc(c);if(l(a)){b=encodeURIComponent(String(b));c=null!=c?"="+encodeURIComponent(String(c)):"";if(b+=c){c=a.indexOf("#");0>c&&(c=a.length);d=a.indexOf("?");if(0>d||d>c){d=c;var f="";}else f=a.substring(d+1,c);a=[a.substr(0,d),f,a.substr(c)];c=a[1];a[1]=b?c?c+"&"+b:b:c;a=a[0]+(a[1]?"?"+a[1]:"")+a[2];}return a}a.m(b,c);return a}function tc(a){this.gb=22;this.g=[];this.H=new bc;this.da=this.fb=this.C=this.Ea=this.a=this.Fa=this.j=this.ca=this.f=this.J=this.h=null;this.sc=!0;this.zc=this.L=0;this.uc=!!n("internalChannelParams.failFast",a);this.Ga=this.v=this.s=this.l=this.i=this.b=null;this.eb=!0;this.B=this.jb=this.K=-1;this.Y=this.u=this.A=0;this.tc=n("internalChannelParams.baseRetryDelayMs",a)||5E3;this.Fc=n("internalChannelParams.retryDelaySeedMs",a)||1E4;this.pc=n("internalChannelParams.forwardChannelMaxRetries",a)||
2;this.ib=n("internalChannelParams.forwardChannelRequestTimeoutMs",a)||2E4;this.xc=a&&a.ue||void 0;this.F=void 0;this.vc=0;this.T=a&&a.supportsCrossDomainXhr||!1;this.I="";this.c=new fc(a&&a.concurrentRequestLimit);this.U=new ic;this.o=a&&void 0!==a.backgroundChannelTest?a.backgroundChannelTest:!0;(this.hb=a&&a.fastHandshake||!1)&&!this.o&&(this.o=!0);a&&a.se&&(this.eb=!1);}e=tc.prototype;e.qb=8;e.w=1;e.bd=function(a,b,c){M(0);this.Ea=b;this.ca=c||{};this.o&&(this.H.b=[],this.H.a=!1);this.Dc(a);};
e.Qa=function(){this.lb();if(3==this.w){var a=this.L++,b=this.C.N();b.m("SID",this.I);b.m("RID",a);b.m("TYPE","terminate");this.ea(b);(new P(this,a,void 0)).Ld(b);}this.Yb();};e.Dc=function(a){this.v=new ac(this);null===this.f&&this.v.Wc(this.h);var b=a;this.f&&this.h&&(b=sc(a,this.f,this.h));this.v.Vc(b);};e.Cc=function(){this.C=this.Cb(this.Ea);this.Ma();};
e.lb=function(){this.v&&(this.v.abort(),this.v=null);this.a&&(this.a.cancel(),this.a=null);this.l&&(h.clearTimeout(this.l),this.l=null);this.ra();this.c.cancel();this.i&&(h.clearTimeout(this.i),this.i=null);};e.cd=function(a){this.h=a;};e.Pd=function(a){this.J=a;};e.Nd=function(a){this.f=a;};e.Od=function(a){this.j=a;};e.kc=function(a){this.Fa=a;};e.Rd=function(){this.T=!0;};e.jc=function(a){this.b=a;};e.sd=function(){return !this.Ga};e.Xa=function(a){this.g.push(new ec(this.zc++,a));3==this.w&&this.Ma();};
e.Jc=function(){return this.uc?0:this.pc};e.Tb=function(){return 0==this.w};e.Ma=function(){this.c.Ub()||this.i||(this.i=N(v(this.dc,this),0),this.A=0);};e.xd=function(a){if(this.c.Mc()>=this.c.f-(this.i?1:0))return !1;if(this.i)return this.g=a.j.concat(this.g),!0;if(1==this.w||2==this.w||this.A>=this.Jc())return !1;this.i=N(v(this.dc,this,a),this.Eb(this.A));this.A++;return !0};e.dc=function(a){this.i=null;this.Xd(a);};
e.Xd=function(a){1==this.w?a||(this.Fd(),this.w=2):3==this.w&&(a?this.Wb(a):0==this.g.length||this.c.Ub()||this.Wb());};
e.Fd=function(){this.L=Math.floor(1E5*Math.random());var a=this.L++,b=new P(this,a,void 0),c=this.h;this.J&&(c?(c=ra(c),ta(c,this.J)):c=this.J);null===this.f&&b.ha(c);var d=this.wb(b),f=this.C.N();f.m("RID",a);0<this.gb&&f.m("CVER",this.gb);this.o&&this.j&&f.m("X-HTTP-Session-Id",this.j);this.ea(f);this.f&&c&&sc(f,this.f,c);this.c.Ha(b);this.hb?(f.m("$req",d),f.m("SID","null"),b.Md(),b.cb(f,null)):b.cb(f,d);};
e.Wb=function(a){var b;a?b=a.da:b=this.L++;var c=this.C.N();c.m("SID",this.I);c.m("RID",b);c.m("AID",this.K);this.ea(c);this.f&&this.h&&sc(c,this.f,this.h);b=new P(this,b,this.A+1);null===this.f&&b.ha(this.h);a&&this.Jd(a);a=this.wb(b);b.setTimeout(Math.round(.5*this.ib)+Math.round(.5*this.ib*Math.random()));this.c.Ha(b);b.cb(c,a);};e.ea=function(a){this.b&&Nb({},function(b,c){a.m(c,b);});};
e.wb=function(a){var b=Math.min(this.g.length,1E3),c=this.b?v(this.b.yc,this.b,this):null;c=this.U.c(this.g,b,c);a.Qd(this.g.splice(0,b));return c};e.Jd=function(a){this.g=a.j.concat(this.g);};e.zb=function(){if(!this.a&&!this.l){this.Y=1;var a=this.cc;mb||nb();pb||(mb(),pb=!0);qb.add(a,this);this.u=0;}};e.Ua=function(){if(this.a||this.l||3<=this.u)return !1;this.Y++;this.l=N(v(this.cc,this),this.Eb(this.u));this.u++;return !0};e.cc=function(){this.l=null;this.Vd();};
e.Vd=function(){this.a=new P(this,"rpc",this.Y);null===this.f&&this.a.ha(this.h);this.a.Xc(this.vc);var a=this.fb.N();a.m("RID","rpc");a.m("SID",this.I);a.m("CI",this.Ga?"0":"1");a.m("AID",this.K);this.ea(a);a.m("TYPE","xmlhttp");this.f&&this.h&&sc(a,this.f,this.h);this.F&&this.a.setTimeout(this.F);this.a.bb(a,!0,this.da);};e.na=function(a,b){var c=a.l;c&&this.c.kb(c);this.Ga=this.eb&&b;this.B=a.c;this.Cc();};e.ab=function(a){this.B=a.c;this.X(2);};
e.bc=function(a,b){if(0!=this.w&&(this.a==a||this.c.Ta(a)))if(this.B=a.o,!a.s&&this.c.Ta(a)&&3==this.w){try{var c=this.U.a(b);}catch(d){c=null;}r(c)&&3==c.length?this.ld(c,a):this.X(11);}else(a.s||this.a==a)&&this.ra(),B(b)||(c=this.U.a(b),this.Ad(c,a));};e.ld=function(a,b){0==a[0]?this.kd(b):(this.jb=a[1],0<this.jb-this.K&&this.Ud(a[2])&&!this.s&&(this.s=N(v(this.yd,this),6E3)));};e.kd=function(a){if(!this.l){if(this.a)if(this.a.u+3E3<a.u)this.ra(),this.a.cancel(),this.a=null;else return;this.Ua();M(18);}};
e.Ud=function(a){return 37500>a&&!this.sd()&&0==this.u};e.Oa=function(a){return this.sc?this.b?this.b.$c(a):a:null};e.yd=function(){null!=this.s&&(this.s=null,this.a.cancel(),this.a=null,this.Ua(),M(19));};e.ra=function(){null!=this.s&&(h.clearTimeout(this.s),this.s=null);};
e.Va=function(a){var b=null;if(this.a==a){this.ra();this.a=null;var c=2;}else if(this.c.Ta(a))b=a.j,this.c.gc(a),c=1;else return;this.B=a.o;if(0!=this.w)if(a.b)1==c?(c=x()-a.u,K.dispatchEvent(new zb(K,a.i?a.i.length:0,c,this.A)),this.Ma()):this.zb();else{var d=a.c;if(3==d||0==d&&0<this.B||!(1==c&&this.xd(a)||2==c&&this.Ua()))switch(b&&0<b.length&&this.c.wc(b),d){case 1:this.X(5);break;case 4:this.X(10);break;case 3:this.X(6);break;default:this.X(2);}}};
e.Eb=function(a){var b=this.tc+Math.floor(Math.random()*this.Fc);this.Pa()||(b*=2);return b*a};e.ad=function(a){if(this.o&&(a=a.a)){var b=a.ga("X-Client-Wire-Protocol");b&&this.c.kb(b);this.j&&(a=a.ga("X-HTTP-Session-Id"))&&(this.kc(a),this.C.m(this.j,a));}};
e.Ad=function(a,b){for(var c=this.b&&this.b.Ia?[]:null,d=0;d<a.length;d++){var f=a[d];this.K=f[0];f=f[1];if(2==this.w)if("c"==f[0]){this.I=f[1];this.da=this.Oa(f[2]);var g=f[3];null!=g&&(this.qb=g);f=f[5];null!=f&&"number"==typeof f&&0<f&&(this.F=1.5*f);this.ad(b);this.w=3;this.b&&this.b.pb();this.Wd(b);}else"stop"!=f[0]&&"close"!=f[0]||this.X(7);else 3==this.w&&("stop"==f[0]||"close"==f[0]?(c&&0!=c.length&&(this.b.Ia(this,c),c.length=0),"stop"==f[0]?this.X(7):this.Qa()):"noop"!=f[0]&&(c?c.push(f):
this.b&&this.b.ob(f)),this.u=0);}c&&0!=c.length&&this.b.Ia(this,c);};e.Wd=function(a){this.fb=this.Bb(this.da,this.Ea);a.s?(this.c.gc(a),a.Kd(this.F),this.a=a):this.zb();};e.X=function(a){if(2==a){var b=null;this.b&&(b=null);var c=v(this.Zd,this);b||(b=new S("//www.google.com/images/cleardot.gif"),h.location&&"http"==h.location.protocol||b.ma("https"),b.za());jc(b.toString(),c);}else M(2);this.zd(a);};e.Zd=function(a){a?M(2):M(1);};e.zd=function(a){this.w=0;this.b&&this.b.nb(a);this.Yb();this.lb();};
e.Yb=function(){this.w=0;this.B=-1;if(this.b){if(0!=this.c.Xb().length||0!=this.g.length)this.c.Bc(),la(this.g),this.g.length=0;this.b.mb();}};e.Cb=function(a){return this.ub(null,a)};e.Bb=function(a,b){return this.ub(this.Da()?a:null,b)};
e.ub=function(a,b){var c=Vb(b);if(""!=c.c)a&&c.ka(a+"."+c.c),c.la(c.h);else{var d=h.location,f;a?f=a+"."+d.hostname:f=d.hostname;c=Wb(d.protocol,f,+d.port,b);}this.ca&&qa(this.ca,function(a,b){c.m(b,a);});a=this.j;b=this.Fa;a&&b&&c.m(a,b);c.m("VER",this.qb);this.ea(c);return c};e.sa=function(a){if(a&&!this.T)throw Error("Can't create secondary domain capable XhrIo object.");a=new X(this.xc);a.Sd(this.T);return a};e.Pa=function(){return !!this.b&&!0};e.Da=function(){return this.T};function uc(){}e=uc.prototype;e.Ia=null;e.pb=function(){};e.ob=function(){};e.nb=function(){};e.mb=function(){};e.yc=function(){};e.$c=function(a){return a};function vc(a){for(var b=arguments[0],c=1;c<arguments.length;c++){var d=arguments[c];if(0==d.lastIndexOf("/",0))b=d;else{var f;(f=""==b)||(f=b.length-1,f=0<=f&&b.indexOf("/",f)==f);f?b+=d:b+="/"+d;}}return b}function wc(){if(E&&!(10<=Number(Ia)))throw Error("Environmental error: no available transport.");}wc.prototype.a=function(a,b){return new Y(a,b)};
function Y(a,b){I.call(this);this.a=new tc(b);this.g=a;this.o=b&&b.testUrl?b.testUrl:vc(this.g,"test");this.b=b&&b.messageUrlParams||null;a=b&&b.messageHeaders||null;b&&b.clientProtocolHeaderRequired&&(a?a["X-Client-Protocol"]="webchannel":a={"X-Client-Protocol":"webchannel"});this.a.cd(a);a=b&&b.initMessageHeaders||null;b&&b.messageContentType&&(a?a["X-WebChannel-Content-Type"]=b.messageContentType:a={"X-WebChannel-Content-Type":b.messageContentType});b&&b.tb&&(a?a["X-WebChannel-Client-Profile"]=
b.tb:a={"X-WebChannel-Client-Profile":b.tb});this.a.Pd(a);(a=b&&b.httpHeadersOverwriteParam)&&!B(a)&&this.a.Nd(a);this.l=b&&b.supportsCrossDomainXhr||!1;this.h=b&&b.sendRawJson||!1;(b=b&&b.httpSessionIdParam)&&!B(b)&&(this.a.Od(b),a=this.b,null!==a&&b in a&&(a=this.b,b in a&&delete a[b]));this.f=new Z(this);}y(Y,I);e=Y.prototype;e.addEventListener=function(a,b,c,d){Y.S.addEventListener.call(this,a,b,c,d);};e.removeEventListener=function(a,b,c,d){Y.S.removeEventListener.call(this,a,b,c,d);};
e.Yc=function(){this.a.jc(this.f);this.l&&this.a.Rd();this.a.bd(this.o,this.g,this.b||void 0);};e.close=function(){this.a.Qa();};e.Zc=function(a){if(l(a)){var b={};b.__data__=a;this.a.Xa(b);}else this.h?(b={},b.__data__=hb(a),this.a.Xa(b)):this.a.Xa(a);};e.G=function(){this.a.jc(null);delete this.f;this.a.Qa();delete this.a;Y.S.G.call(this);};
function xc(a){Eb.call(this);var b=a.__sm__;if(b){a:{for(var c in b){a=c;break a}a=void 0;}(this.f=a)?(a=this.f,this.data=null!==b&&a in b?b[a]:void 0):this.data=b;}else this.data=a;}y(xc,Eb);function yc(){Fb.call(this);this.status=1;}y(yc,Fb);function Z(a){this.a=a;}y(Z,uc);Z.prototype.pb=function(){this.a.dispatchEvent("a");};Z.prototype.ob=function(a){this.a.dispatchEvent(new xc(a));};Z.prototype.nb=function(a){this.a.dispatchEvent(new yc(a));};Z.prototype.mb=function(){this.a.dispatchEvent("b");};var zc=w(function(a,b){function c(){}c.prototype=a.prototype;var d=new c;a.apply(d,Array.prototype.slice.call(arguments,1));return d},wc);/*

 Copyright 2017 Google Inc.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

   http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
wc.prototype.createWebChannel=wc.prototype.a;Y.prototype.send=Y.prototype.Zc;Y.prototype.open=Y.prototype.Yc;Y.prototype.close=Y.prototype.close;Ab.NO_ERROR=0;Ab.TIMEOUT=8;Ab.HTTP_ERROR=6;Bb.COMPLETE="complete";Db.EventType=O;O.OPEN="a";O.CLOSE="b";O.ERROR="c";O.MESSAGE="d";I.prototype.listen=I.prototype.Lb;X.prototype.listenOnce=X.prototype.Mb;X.prototype.getLastError=X.prototype.dd;X.prototype.getLastErrorCode=X.prototype.Db;X.prototype.getStatus=X.prototype.aa;X.prototype.getStatusText=X.prototype.Fb;
X.prototype.getResponseJson=X.prototype.Nc;X.prototype.getResponseText=X.prototype.va;X.prototype.send=X.prototype.xa;var src={createWebChannelTransport:zc,ErrorCode:Ab,EventType:Bb,WebChannel:Db,XhrIo:X};
var src_1 = src.createWebChannelTransport;
var src_2 = src.ErrorCode;
var src_3 = src.EventType;
var src_4 = src.WebChannel;
var src_5 = src.XhrIo;

export default src;
export { src_1 as createWebChannelTransport, src_2 as ErrorCode, src_3 as EventType, src_4 as WebChannel, src_5 as XhrIo };
